//Resolution 1920px*1080px
@media(min-width: 1920px){
    .table-container{
        height: 700px;
    }
}

//Resolution 1536px*864px
@media(min-width: 1536px) and (max-width:1919px){
    .table-container{
        height: 580px !important;
    }
    .modal-content{
        margin-left: -50px;
    }
}

//Resolution 1440px*900px
@media(min-width: 1440px) and (max-width:1535px){
    .table-container{
        height: 690px !important;
    }
    .modal-content{
        margin-left: -55px;
    }
}

//Resolution 1366px*768px
@media(min-width: 1366px) and (max-width:1439px){
    .pt-center{
        padding-top: 5%;
    }
    .table-container{
        height: 555px !important;
    }
}

//Resolution 1280px*800px
@media(min-width: 1280px) and (max-width:1365px){
    .table-container{
        height: 340px !important;
    }
    .pt-center {
        padding-top: 1%;
    }
    .facility-font{
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .facility-font:hover{
        overflow: visible; 
        white-space: normal;
        height:auto; 
    }
}

//Resolution 800px*600px
@media(min-width: 800px) and (max-width:1279px){
    .pt-center{
        padding-top: 2%;
    }
    .table-container{
        height: 280px !important;
    }

    .bottom_colapse_bar{
        height: 40px!important;
    }
    .btn-table {
        margin-left: 4px;
    }
    td.mat-cell:last-of-type {
        padding-right: 20px;
    }
    .sidenav {
        padding-top: 110px;
        bottom: 100px;
    }
    .menu_item{
        padding: 18px 25px 12px 26px !important;
        height: 40px;
    }
    .exit-position {
        bottom: 40px;
    }

    .helpCenter-frame{
        margin-left: 22px!important;
    }
    .facility-font{
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .facility-font:hover{
        overflow: visible; 
        white-space: normal;
        height:auto; 
    }
}

@media (max-height: 950px) and (min-height:601px) {
    .menu_item{
        height: 52px!important;
        padding: 18px 20px 20px 26px !important;
    }
    .upload_status{
        padding: 13px 21px 20px 22px !important;
    }
    .sidenav {
        bottom: 100px !important;
    }
    #sn_SmallLogo{
        margin-top: 40px !important;
    }
}

@media (max-height: 600px) {
    #profile_img{
        margin-bottom: 17px !important;
        margin-top: 20px !important;
        margin-top: 15px;
    }
    .menu_item {
        height: 40px !important;
        padding: 10px 20px 0px 26px !important;
    }
    .exit-position {
        bottom: 40px !important;
    }
    #sn_SmallLogo{
        margin-top: 10px !important;
    }
    .bottom_bar{
        height: 40px !important;
        padding: 0px 12px 0px 12px !important;
    }
    .sidenav {
        bottom: 80px !important;
    }
    .upload_status{
        padding-left: 22px !important;
    }
}
