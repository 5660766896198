.btn-primary {
    background-color: #21918E !important;
    font-weight: 500;
    color: #ffffff !important;
    font-family: "SN Emeric", sans-serif;
    border: 0 !important;
    height: auto !important;
    padding: 9px 20px !important;
    margin-right: 5px;
    width: 100%;
}

.btn:focus{
    box-shadow: none;
}

.btn-SN-primary{
    background: #4CFFCE;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    color: #0A0A0A;
    width: 224px;
    height: 40px;
    font-size: 24px;
    font-weight: 450;
    line-height: 125%;
    border: none;
    font-family: "SNEmeric-Core", sans-serif;
}

.btn-SN-primary:hover{
    background: #00CB9D;
}

.btn-SN-primary:active{
    background: #8BFFFF;
}

.btn-SN-primary:disabled{
    background: #2B846C;
}

.btn-SN-default{
    background: #323232;
    border: 1px solid #969696;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    color: #EBEBEB;
    width: 224px;
    height: 40px;
    font-size: 24px;
    font-weight: 450;
    line-height: 125%;
    font-family: "SNEmeric-Core", sans-serif;
}

.btn-SN-default:hover{
    background: #3B4041;
    color: #EBEBEB;
}

.btn-SN-default:active{
    background: #1B1B1B;
}

.btn-SN-default:disabled{
    background: #232524;
    color: #808181;
    border: none;
}

.br-4{
    border-radius: 4px !important;
}

.btn-table{
    margin-left: 40px;
    margin-top: 12px;
    width: 180px;
    height: 30px;
    line-height: 120%;
}

.btn-db-filter{
    width: 204px;
    height: 30px;
    line-height: 80%;
    border-radius: 4px;
}
@media(max-width: 1919px){
    .modal-content .btn-SN-primary{
        width: 224px;
        height: 30px;
        font-size: 20px;
        line-height: 90%;
    }
    .modal-content .btn-SN-default{
        width: 224px;
        height: 30px;
        font-size: 20px;
        line-height: 90%;
    }
    .width-success{
        width: 270px !important;
        margin-left: 0rem !important;
        margin-right: 0rem !important;
    }
}