

@font-face {
    font-family: "SN Emeric";
    src: url("assets/fonts/SNEmeric-Book.ttf");
    src: url('assets/fonts/SNEmeric-BookItalic.ttf');
    src: url('assets/fonts/SNEmeric-ExtraBold.ttf');
    src: url('assets/fonts/SNEmeric-ExtraBoldItalic.ttf');
    src: url('assets/fonts/SNEmeric-LightItalic.ttf');
    src: url('assets/fonts/SNEmeric-SemiBold.ttf');
    src: url('assets/fonts/SNEmeric-SemiBoldItalic.ttf');
    src: url('assets/fonts/SNEmeric-Core.ttf');
    src: url('assets/fonts/SNEmeric-CoreItalic.ttf');
    src: url('assets/fonts/SNEmeric-Light.ttf');
    src: url('assets/fonts/SNEmeric-Book.ttf') format('truetype');
}

@font-face {
    font-family: "SNEmeric-Core";
    src: url('assets/fonts/SNEmeric-Core.ttf');
}

@font-face {
    font-family: "SNEmeric-Light";
    src: url('assets/fonts/SNEmeric-Light.ttf');
}

@font-face {
    font-family: "SNEmeric-Book";
    src: url("assets/fonts/SNEmeric-Book.ttf");
  }

  @font-face {
    font-family: "SNEmeric-SemiBold";
    src: url('assets/fonts/SNEmeric-SemiBold.ttf');
  }

@import '~@angular/material/prebuilt-themes/indigo-pink.css';

body {
    font-family: 'SN Emeric', Arial, sans-serif;
    background: #000000;
    width: 100%;
    height: auto;
}

.font-SN-book{
    font-family: "SNEmeric-Book", sans-serif !important;
}

.font-SN-core{
    font-family: "SNEmeric-Core", sans-serif !important;
}

.font-SN-light{
    font-family: "SNEmeric-Light", sans-serif !important;
}

html, body {
    height: 100%;
}

a, a:hover, a:visited, a:active {
    font-weight: 16px;
    color: #575756;
}

h1 {
    color: white;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

.container-fluid, .row {
    padding: 0px;
    width: 100%;
}

.home {
    width: 100%;
    height: auto;
    background-size: cover;
}

.home .container {
    border-radius: 10px;
    margin-top: 11%;
    padding-bottom: 50px;
    margin-bottom: 5%;
}

#login {
    width: 563px;
}

#newUser {
    max-width: 760px;
    margin-top: 14%;
}

.home_logo {
    margin-top: -70px;
    margin-left: 61px;
    padding-bottom: 30px;
}
#newUser .home_logo {
    margin-top: -65px;
    margin-left: calc(50% - 256px);
    padding-bottom: 30px;
}
#newUser .home_logo img{
    margin-left: calc(50% - 158px);
    margin-top: 6%;
}
.nav-tabs {
    font-size: 30px;
    color: #575756;
    font-weight: bold;
    position: relative;
}
.nav-tabs-active {
    color: #cdcdcd;
}
a.nav-tabs, a.nav-tabs:active, a.nav-tabs:visited, a.nav-tabs:hover {
    text-decoration: none;
    color: #575756;
    border: 0;
}
a.nav-tabs-active, a.nav-tabs-active:active, a.nav-tabs-active:visited, a.nav-tabs-active:hover {
    text-decoration: none;
    color: #cdcdcd !important;
    padding-bottom: 15px;
    font-weight: bold !important;
}
.form-signin {
    margin: 0 0 0 80px;
}
#newUser .form-signin {
    margin: auto;
    width: 80%;
}
#newUser > .row {
    margin: auto;
}
.form-signin nav {
    margin-bottom: 50px;
}
.form-signin .bottom-links{
    margin-top: 28px;
}
.form-signin nav a:first-child {
    margin-right: 53px;
}
.form-signin nav > a.tab-seprator:after {
    content: '|';
    color: transparent !important;
    border-right: 2px solid #ccc;
    position: absolute;
    right: -30px;
    line-height: 30px;
    top: 4px;
}
.login_content {
    width: 402px;
    padding: 0;
}
.login_content input {
    height: 40px;
    margin-bottom: 30px;
}
.login_content input.is-invalid {
    margin-bottom: 0;
}
label {
    font-size: 16px;
    font-weight: bold;
    color: #cdcdcd;
    float: left;
}
::ng-deep .form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #cdcdcd;
    background-color: #555555;
    background-image: none;
    border-radius: 6px;
    border: 1px solid #555555
  }


#newUser .login_content {
    width: 100%;
}
.login_content ul {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow: hidden;
}
.login_content ul li {
    float: left;
    list-style: none;
    width: 49%;
    margin-left: 3px;
    min-height: 112px;
}
.login_content ul li input {
    width: calc(100% - 15px);
}
p {
    margin: 20px 0;
}
.pv-heading{
    cursor: pointer;
  }

/* v0.7 end */
/* common style login component */
.red{color:red;}

@media (max-width: 768px) {
    .home .container {
        margin-top: 22%;
    }
    #newUser {
        margin-top: 10%;
    }
    #newUser .home_logo {
        margin-top: -55px;
    }
}

@media (max-width: 1280px) {
    #newUser {
        margin-top: 10%;
    }
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.warn-msg{
    font-weight:600 !important;
    color: red !important;
    margin-bottom: 15px;
}
.alert {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    text-align: center;
    font-weight: 600;
  }
  .alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  .alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }
  .alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    width: 100%
  }
  .plist {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    margin-left: auto !important;
    margin-right: auto !important;
    float: left;
}
.plist .table {
    margin-bottom: 30px !important;
}
.bgGray {
    background-color: #252525;
    margin: 20px;
    border-radius: 6px;
}
.right_section {
    width: auto;
}
.body-section {
    width: 100%;
    padding: 50px;
    // min-height: calc(90vh - 65px);
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    background-color: #252525;
}
.table {
    width: 100%;
    margin-bottom: 1rem;
    background-color: #252525;
}
tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: none;
    border-width: 0;
}
.table td, .table th {
    vertical-align: middle !important;
    border-top: none !important;
    padding: .75rem;
}
.sidenav {
    height: 100%;
    width: 256px;
    background-color: #303030 ;
    overflow-x: hidden;
    transition: 0.5s;
    bottom: 105px;
    padding-top: 85px;
}
.sidenav a {
    text-decoration: none;
    font-size: 16px;
    font-weight: normal;
    display: block;
    transition: 0.3s;
    color: #EBEBEB;
}
.sidenav a:hover {
    color: #a7a5a1;
}
.sidenav ul {
    margin: 0;
    padding: 0;
}
.sidenav ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.upload-frame{ 
    width: -webkit-fill-available;
    background:#000000 ;
    margin-left: 255px;
    border: 20px solid #000000;
}

@media screen and (max-width: 1919px) {
    .upload-frame{ 
        margin-left: 72px!important;
    }
}


.pt-center{
    padding-top: 8%;
}

.align-center{
    text-align: center !important;
}

.align-left{
    text-align: left !important;
}

.cursor-pointer{
    cursor: pointer;
}

.display-selection h5{
    font-family: 'SN Emeric', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 30px;
    /* identical to box height, or 125% */
  
    letter-spacing: 0.15px;
  
    /* Text(on_black)/High_emphasis */
  
    color: #EBEBEB;
  }

  .display-selection img{
    margin-bottom: 5px;
  }

  .header-container{
    height: 72px;
    border-radius: 8px;
    text-align: left;
    background: #151817;
    box-shadow: 0px 7px 4px 4px rgb(0 0 0 / 50%);
    font-family: 'SN Emeric', sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-left: 24px;
    padding-top: 24px;
    color: #EBEBEB;
  }

  .pop-up-close{
    width: 14px;
    height: 14px;
    cursor: pointer;
    float: right !important;
    margin: 15px 15px 0px 0px;
}
.margin-displaySelection{
    margin-top: 30px;
}
.noScroll{
    overflow-y: hidden !important;
}

/* this makes it so that sort header arrow is always shown: */
.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {  
    color: #969696;
    
  }
/* Hide the default sort arrow display: */
.mat-sort-header-stem {
    display: none  !important;
}
.mat-sort-header-pointer-left, .mat-sort-header-pointer-right {
    display: none  !important;
}
.mat-sort-header-pointer-middle{
    width: 0px !important;
    height: 0px !important;
}
.mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-indicator {
        transform: translateY(0px) !important;    
        opacity: 1;
}

/* show two carets on top of each other when not sorted takes place: */
.mat-sort-header-arrow {
    .mat-sort-header-indicator {
        &::before {
            content: url('/assets/images/upDown.svg');
            position: absolute;
            padding: 15px 0px 0px 0px; 
        }
    }
}

/* show ascending caret when sorted ascending:*/
[aria-sort="ascending"] {
 .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: url('/assets/images/upArrow.svg');
          position: absolute;
          padding: 0px 0px 2px 0px; 
        }
      }
    }
  }
  
 /* show descending caret when sorted descending: */
[aria-sort="descending"] {
.mat-sort-header-arrow {
      .mat-sort-header-indicator {
        &::before {
          content: url('/assets/images/downArrow.svg');
          position: absolute;
          padding: 0px 0px 10px 0px; 
        }
      }
    }
  }

.visibility-hidden{
    visibility: hidden;
    height: 0px;
    width: 0px;
}