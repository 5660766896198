body{
    font-family: "SN Emeric", sans-serif;
    font-style: normal;
    color: #EBEBEB;
    font-weight: 450;
    font-size: 24px;
    background-color: #000000;
}  

h6{
    font-size: 24px;
    line-height: 24px;
}

h5{
    font-size: 24px;
    line-height: 30px;
}

.font-weight-350{
    font-weight: 350 !important;
}

.font-weight-450{
    font-weight: 450 !important;
}

.font-weight-600{
    font-weight: 600 !important;
}

.font-weight-800{
    font-weight: 800 !important;
}

.font-weight-bold{
    font-weight: bold !important;
}

.font-14{
    font-size: 14px !important;
}

.font-16{
    font-size: 16px !important;
}

.font-18{
    font-size: 18px !important;
}

.font-24{
    font-size: 24px !important;
}

.font-30{
    font-size: 30px !important;
}

.font-34{
    font-size: 34px !important;
}

.font-29{
    font-size: 29px !important;
}

.color-96{
    color: #969696;
}

.color-white{
    color: white;
}