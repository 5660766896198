.modal-content{
    background: #323232 !important;
    box-shadow: 15px 15px 100px 40px rgba(0, 0, 0, 0.2);
    border-radius: 16px !important;
}

.modal{
    position: static!important;
}

